import React from "react";
import {
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import CodeIcon from "@material-ui/icons/Code";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

const Project = ({project, classes}) =>
  (<VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={ project.iconStyle }
    icon={ <CodeIcon /> }
  >
    <Typography
      variant="h5"
      color="primary"
      className="vertical-timeline-element-title"
    >
      { project.role }
    </Typography>
    <Typography
      variant="h6"
      color="primary"
      className="vertical-timeline-element-subtitle"
    >
      { project.institute }
    </Typography>
    <Typography variant="body2" color="primary">
      { project.time }
    </Typography>
    <Typography variant="body2" color="primary">
      { project.Extra ? <project.Extra classes={ classes } /> : null }
      { project.skills.map(
        (tool, idx) => (
          <Chip
            key={idx}
            component="span"
            label={ tool }
            color="secondary"
            className={ classes.chip }
          />
        )
      ) }
      <br />
      { project.description }
    </Typography>
  </VerticalTimelineElement>);

export default Project;
