import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import "react-vertical-timeline-component/style.min.css";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";

import Experience from './Experience';
import Projects from './Projects';

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  titleText: {
    color: "red !important"
  }
});


class TimeLine extends React.Component {
  state = {
    showHackathons: false
  };

  toggleTimeLine = () => {
    this.setState(
      state => ({
        showHackathons: !state.showHackathons
      })
    )
  };

  render() {
    const { showHackathons } = this.state;
    const { classes } = this.props;
    const History = showHackathons ? Projects : Experience;

    return (
      <section
        id="history"
        style={ {
          backgroundColor: "black",
          padding: "50px"
        } }
        ref={ sectionRef => (this.sectionRef = sectionRef) }
      >
        <Grid
          container
          alignItems="center"
          direction="row"
          justify="center"
          style={ {
            paddingTop: "20px"
          } }
        >
          <ButtonBase
            onClick={ this.toggleTimeLine
            }
          >
            <Typography variant="h3" align="center" style={ styles.text }>
              <span
                style={ {
                  color: showHackathons ? "#fff" : "rgb(0, 145, 234)"
                } }
              >
                Experience
              </span>
              <span style={ { color: "#fff" } }>{ " / " }</span>
              <span
                style={ {
                  color: this.state.showHackathons
                    ? "rgb(0, 145, 234)"
                    : "#fff"
                } }
              >
                Projects
              </span>
            </Typography>
          </ButtonBase>
        </Grid>
        { <History { ...{ classes } } /> }
      </section>
    );
  }
}

TimeLine.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TimeLine);
