import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WhatshotIcon from "@material-ui/icons/Whatshot";

import Project from "./Project";
import projectData from "./projectData";

const Projects = (props) => {
  const { classes } = props;
  return (
    <div>

      <VerticalTimeline animate={ false }>
        { projectData.map((project, idx) => {
          return (<Project key={idx} project={project} classes={classes} />)
        }) }

        <VerticalTimelineElement
          iconStyle={ { background: "black", color: "red" } }
          icon={ <WhatshotIcon /> }
        />
      </VerticalTimeline>
    </div>
  );
};

export default Projects;
