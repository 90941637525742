import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@material-ui/icons/Work";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

const experiences = [
  {
    position: "Software Developer",
    company: "D2L",
    duration: "Sep. 2020 - present",
    skills: [
      "React/Redux",
      "C#/ASP.NET",
      "AWS",
      "Docker",
      "ELK",
      "Terraform",
      "Jenkins"
    ],
    iconStyle: { background: "black", color: "#EA760f" },
  },
  {
    position: "Software Engineer",
    company: "data.world",
    duration: "Sep. 2019 - Apr. 2020",
    skills: [
      "React/Redux",
      "Cypress",
      "Nodejs",
      "Java",
      "Elasticsearch",
    ],
    iconStyle: { background: "black", color: "#6B90C2" },
  },
  {
    position: "Frontend Developer",
    company: "Genesys",
    duration: "Aug. 2018 - Dec. 2018",
    skills: [
      "React/Redux",
      "Typescript",
      "Immutable.js",
      "ReSelect"
    ],
    iconStyle: { background: "black", color: "#ff4f1f" },
  },
  {
    position: "Full Stack Developer",
    company: "PerkinElmer Inc.",
    duration: "Jan. 2018 - Apr. 2018",
    skills: [
      "Scikit-learn",
      "Keras",
      "Tensorflow",
      "React Native",
      "Flask",
      "MongoDB"
    ],
    iconStyle: { background: "black", color: "#024598" },
  },
  {
    position: "Junior Software Developer",
    company: "Envision IT Inc.",
    duration: "Apr. 2017 - Sept. 2017",
    skills: [
      "C#",
      "ASP.NET",
      "Azure",
      "CRON job",
      "MySQL",
      "CKEditor",
      "jQuery",
      "Knockout.js"
    ],
    iconStyle: { background: "black", color: "#a6aa11" },
  },
]

const WorkExperience = (props) => {
  const { classes } = props;
  return (
    <React.Fragment>
        { experiences.map((exp, idx) => {
          return (<VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={ exp.iconStyle }
            icon={ <WorkIcon /> }
            key={idx}
          >
            <Typography
              variant="h5"
              color="primary"
              className="vertical-timeline-element-title"
            >
              { exp.position }
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className="vertical-timeline-element-subtitle"
            >
              { exp.company }
            </Typography>
            <Typography variant="body2" color="primary">
              { exp.duration }
            </Typography>
            <Typography variant="body2" color="primary">
              { exp.skills.map((tool, cidx) => (
                <Chip key={cidx} label={ tool } color="secondary" component="span" className={ classes.chip } />
              )) }
            </Typography>
          </VerticalTimelineElement>);
        }) }
      </React.Fragment>
  );
};

export default WorkExperience;
