import React from "react";
import { render } from "react-dom";
import Intro from "./Intro";
import TimeLine from "./TimeLine";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#4f5b62",
      main: "#243447",
      dark: "#000a12",
      contrastText: "#fff"
    },
    secondary: {
      light: "#64c1ff",
      main: "#0091ea",
      dark: "#0064b7",
      contrastText: "#fff"
    },
    type: "dark"
  }
});


const App = () => (
  <MuiThemeProvider theme={theme}>
    <Intro />
    <TimeLine />
  </MuiThemeProvider>
);

render(<App />, document.getElementById("root"));
