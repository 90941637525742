import React from "react";
import Grid from "@material-ui/core/Grid";
import withTheme from "@material-ui/core/styles/withTheme";
import Icon from "@material-ui/core/Icon";
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';


const contactItems = [
  { link: "https://github.com/tkhanna42", icon: "fab fa-github", title: "GitHub" },
  { link: "https://www.linkedin.com/in/tkhanna42/", icon: "fab fa-linkedin", title: "LinkedIn" },
  { link: "mailto:tkhanna@edu.uwaterloo.ca", icon: "fas fa-envelope", title: "Email" },
  { link: "./Tarun_Khanna_Resume.pdf", icon: "fas fa-file-alt", title: "Resume" }
];

const styles = {
  footer: {
    width: "100%",
    position: "absolute",
    bottom: "0"
  }
};

const FooterItem = ({title, link, icon}) => (
  <Grid item xs={ 2 } sm={ 1 } align="center">
    <Tooltip title={ title } aria-label="Add">
      <IconButton
        size="small"
        href={ link }
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon className={ icon } />
      </IconButton >
    </Tooltip>
  </Grid>
)

const Footer = () => (
  <footer style={ styles.footer }>
    <Grid
      container
      alignItems="center"
      direction="row"
      justify="center"
      style={ {
        backgroundColor: "rgba(0,0,0,0.4)",
        height: "4.25em",
      } }
    >
      { contactItems.map((contactItem, idx) => (
        <FooterItem key={idx} {...contactItem} />
      )) }
    </Grid>
  </footer>
);

export default withTheme(Footer);
