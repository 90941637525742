import React from "react";
import {
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

const Education = ({ classes }) =>
  (<VerticalTimelineElement
    className="vertical-timeline-element--education"
    iconStyle={ { background: "black", color: "gold" } }
    icon={ <SchoolIcon /> }
  >
    <Typography
      variant="h5"
      color="primary"
      className="vertical-timeline-element-title"
    >
      University of Waterloo
    </Typography>
    <Typography
      variant="h6"
      color="primary"
      className="vertical-timeline-element-subtitle"
    >
      Bachelor of Computer Science (Data Science), Honours, Co-op
    </Typography>
    <Typography variant="body2" color="primary">
      2016 - 2021
    </Typography>
    <Typography variant="body2" color="primary">
      { [
        "Object Oriented Programming",
        "Algorithms",
        "Compilers",
        "Database Management",
        "Mathematical Statistics",
        "OS",
        "Numerical Computation",
        "Artificial Intelligence",
        "Graphics (OpenGL)",
        "Big Data (Spark/Hadoop)",
        "Distributed Systems",
      ].map((tool, idx) => (
        <Chip key={idx} label={ tool } color="secondary" component="span" className={ classes.chip } />
      )) }
    </Typography>
  </VerticalTimelineElement>)
  
  export default Education;
  