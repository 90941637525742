import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WhatshotIcon from "@material-ui/icons/Whatshot";

import WorkExperience from "./WorkExperience";

import Education from "./Education";

const Experience = (props) => {
  const { classes } = props;
  return (
    <div>
      <VerticalTimeline animate={ false }>
        <WorkExperience classes={classes} />

        <Education classes={ classes } />

        <VerticalTimelineElement
          iconStyle={ { background: "black", color: "red" } }
          icon={ <WhatshotIcon /> }
        />
      </VerticalTimeline>
    </div>
  );
};

export default Experience;
