import React from "react";

import trianglify from "trianglify";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import withTheme from "@material-ui/core/styles/withTheme";
import Footer from "./Footer";

const styles = {
  text: { color: "#fff" },
  gridItem: {
    paddingBottom: "28px"
  },
  lastNameStyle: {
    color: `rgb(0, 145, 234)`
  },
  display2: {
    fontSize: '2.8125rem',
    lineHeight: '1.13333em',
    marginLeft: '-.02em'
  }
};

const getDateSeed = () => {
  const d = new Date(),
   year = d.getFullYear(),
   month = d.getMonth(),
   day = d.getDate();

  return `${year}-${month}-${day}`
}

const getDimensions = () => {
  const w = window,
    d = document,
    documentElement = d.documentElement,
    body = d.getElementsByTagName('body')[ 0 ],
    width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
    height = w.outerHeight || documentElement.clientHeight || body.clientHeight;

  return { width, height };
}

class Intro extends React.Component {
  constructor(props) {
    super(props)
    this.state = getDimensions();
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.throttledUpdateDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.throttledUpdateDimensions);
  }

  updateDimensions = () => {
    this.setState(getDimensions());
  }

  throttledUpdateDimensions = () => {
    if (!this.eventTimeout) {
      this.eventTimeout = true;
      setTimeout(() => {
        this.eventTimeout = false;
        this.updateDimensions();
      }, 400);
    }
  };

  render() {
    const { width, height } = this.state;
    const pattern = trianglify({
      width,
      height,
      cellSize: 200,
      seed: getDateSeed(),
      colorFunction: trianglify.colorFunctions.sparkle(0.8),
      xColors: [ "#000000", "#252525", "#000000", "#252525", "#000000" ]
    });
    const patternSvg = pattern.toSVG().outerHTML;
    const backgroundImage = `url("data:image/svg+xml;base64,${window.btoa(patternSvg)}")`;
    
    return (
      <section id="home" style={ {
        height: "100vh",
        backgroundImage
      } }>
        <Grid
          container
          alignItems="center"
          direction="column"
          justify="center"
          style={ { paddingTop: "20vh" } }
        >
          <Grid item align="center" xs={ 12 } style={ styles.gridItem }>
            <Typography variant="h3" style={ {...styles.display2, ...styles.text}  }>
              { "Tarun " }
              <span style={ styles.lastNameStyle }>{ "Khanna" }</span>
            </Typography>
          </Grid>
          <Grid item align="center" xs={ 12 } style={ styles.gridItem }>
            <Typography variant="h5" style={ styles.text }>
              { "Software Developer" }
            </Typography>
          </Grid>
          <Grid item align="center" xs={ 12 } style={ styles.gridItem }>
            <Typography variant="h5" style={ styles.text }>
              { "Computer Science '21" }
            </Typography>
            <Typography variant="h5" style={ styles.text }>
              <a
                href="https://uwaterloo.ca/"
                rel="noopener noreferrer"
                target="_blank"
                style={ {
                  color: "#fff",
                  textDecoration: "none"
                } }
              >
                { "University of Waterloo" }
              </a>
            </Typography>
          </Grid>
          <Footer />
        </Grid>
      </section>
    );
  }
}

export default withTheme(Intro);
