import React from "react";
import "react-vertical-timeline-component/style.min.css";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";

const projectData = [
  {
    iconStyle: { background: "black", color: "gold" },
    role: "Software Developer",
    institute: "XPlates",
    time: "Feb. 2018 - Aug. 2018",
    skills: [ "React", "Redux", "MaterialUI/MDL", "Flask", "Postgres" ],
    description:
      "Food delivery startup at MultiVariable Corp."
  },
  {
    iconStyle: { background: "black", color: "rgb(33, 150, 243)" },
    role: "Full Stack Developer",
    institute: "KW Seniors Day Program",
    time: "Jan. 2018 - Aug. 2018",
    skills: [ "Django", "Wagtail", "UIkit", "AWS EB/EC2/RDS", "Postgres" ],
    description:
      "Independently rebuilt modern, AODA compliant responsive website."
  },
  {
    iconStyle: { background: "black", color: "white" },
    role: "HangingOut",
    institute: "McHacks V",
    time: "February 2018",
    skills: [ "React Native", "Firebase", "Node", "Express", "AWS" ],
    description:
      "Cross platform app that displays nearby users to encourage community involvement."
  },
  {
    iconStyle: { background: "black", color: "white" },
    role: "Alzheimer Reminder App",
    institute: "DeltaHacks IV",
    time: "January 2018",
    skills: [ "Android", "SQLiteDatabase", "MediaPlayer" ],
    description:
      "Sets scheduled notifications for medications and send emergency alerts to caregivers. Wrote CRUD operations using SQLiteDatabase API.",
    Extra: ({ classes }) => (<Chip
      label="Innovation Challenge Prize"
      style={ { background: "gold", color: "black" } }
      className={ classes.chip }
      avatar={
        <Avatar style={ { background: "black", color: "gold" } }>
          <StarIcon />
        </Avatar>
      }
    />)
  },
  {
    iconStyle: { background: "black", color: "white" },
    role: "ETHJournal",
    institute: "ETHWaterloo",
    time: "September 2017",
    skills: [ "Solidity", "Truffle Framework", "IPFS", "Storj" ],
    description:
      "A decentralised system for academic peer reviews."
  },
  {
    iconStyle: { background: "black", color: "white" },
    role: "Memonic",
    institute: "MHacks X",
    time: "September 2017",
    skills: [ "Android", "Microsoft Emotion API" ],
    description:
      "App to detect sentiments in image/videos and play related music. Hardcoded sentiment-tone mapping. TODO: Learn generative machine learning techniques"
  },
  {
    iconStyle: { background: "black", color: "white" },
    role: "CrookedPlant",
    institute: "HackThe6ix",
    time: "August 2017",
    skills: [ "Angular 4", "Indico API", "Chart.js" ],
    description:
      "Detect sentiments in written or spoken communication of patient's daily journal entries. Track and visualize sentiments to detect trends."
  },
  {
    iconStyle: { background: "black", color: "white" },
    role: "Heart Disease Dataset",
    institute: "DeepHealth Hackathon",
    time: "February 2017",
    skills: [ "Scikit-learn", "Keras", "Pandas", "Numpy" ],
    description:
      "Achieved 86% accuracy on MCI Heart Disease dataset."
  },
  {
    iconStyle: { background: "black", color: "white" },
    role: "MedAlert",
    institute: "Google's <Br/eak> Inequality Hackathon",
    time: "November 2016",
    skills: [ "Android", "Twilio API" ],
    description:
      "App to assist health workers alert pregnant women by sending scheduled text messages at crucial points of time."
  },
  {
    iconStyle: { background: "black", color: "white" },
    role: "Drag'n'Drop Puzzle App",
    institute: "UWP Hacks (First Hackathon)",
    time: "September 2016",
    skills: [ "VanillaJS", "HTML/CSS" ],
    description:
      "A puzzle game that slices an image into a 4x4 grid and scrambles the pieces. See if you can put it back together without the hint!",
    Extra: ({ classes }) =>
      (<Chip
        label="2nd"
        component="span"
        style={ { background: "gold", color: "black" } }
        className={ classes.chip }
        avatar={
          <Avatar style={ { background: "black", color: "gold" } }>
            <StarIcon />
          </Avatar>
        }
      />)
  },
];

export default projectData;
